/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import FractaLogo from '../../assets/images/fractal-logo-icon.svg';
import { selectUserName } from '../../features/login/loginSlice';
import { message, Progress } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addUserMessage, sendChatMessageThunk } from './ChatSlice';
import UserChatIcon from '../../assets/images/user-chat-icon.svg';
import '../../features/fa-account/styles.scss';

export default function ChatbotWebTradePlan({
  from,
  selectedAccount,
  selectedLogin
}) {
  const dispatch = useDispatch();
  const { chatMessages, currentAccount } = useSelector(
    (state) => state.chatbot
  );
  const { tradingPlan, newtradingPlan } = useSelector(
    (state) => state.tradingPlan
  );
  const userName = useSelector(selectUserName);
  const [chatMessage, setChatMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const firstLetterOfUsername = userName
    ? userName.charAt(0).toUpperCase()
    : '';

  // Use a Set to track unique messages
  const uniqueMessages = [];
  const messageSet = new Set();

  chatMessages.forEach((item) => {
    if (item.type === 'alert') {
      if (!messageSet.has(item.text)) {
        messageSet.add(item.text);
        uniqueMessages.push(item);
      }
    } else {
      uniqueMessages.push(item);
    }
  });

  // Refs to store previous trading plan d
  const prevNewTradingPlan = useRef(newtradingPlan);
  const prevTradingPlan = useRef(tradingPlan);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = textarea.scrollHeight + 'px'; // Set to the scroll height
  };

  useEffect(() => {

    if (
      prevNewTradingPlan.current?.daily_profit_target !==
      newtradingPlan?.daily_profit_target ||
      prevTradingPlan.current?.daily_profit_target !==
      tradingPlan?.daily_profit_target
    ) {
      if (
        newtradingPlan?.daily_profit_target > tradingPlan?.daily_profit_target
      ) {
        const chatMessage = `A Congrats! You have reached your daily profit target of ${newtradingPlan.daily_profit_target}. Great job! Remember,sticking to your daily profit target helps ensure steady growth without unnecessary risk. It's crucial to lock in gains and avoid overtrading, which can lead to emotional decision-making.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (
        newtradingPlan?.profit_target_per_trade >
        tradingPlan?.profit_target_per_trade
      ) {
        const chatMessage = `You've reached your profit target for this trade of ${newtradingPlan.profit_target_per_trade}. Well done! Exiting a trade when it hits your target ensures you capture gains and prevents you from giving back profits.Consistency in achieving these targets contributes to long-term profitability.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (
        newtradingPlan?.maximum_daily_loss > tradingPlan?.maximum_daily_loss
      ) {
        const chatMessage = `You have violated your maximum daily loss target of ${newtradingPlan.maximum_daily_loss}. It's important to honor this limit to protect your capital and maintain a healthy trading mindset. Remember, losses are part of trading, but managing them ensures you'll stay in the game longer and avoid a devastating blow to your account.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (newtradingPlan?.stop_loss < tradingPlan?.stop_loss) {
        const chatMessage = `Your stop loss has been triggered at ${newtradingPlan.stop_loss}. While it might feel frustrating, setting and respecting stop losses is crucial for capital preservation. It helps you manage risk effectively by limiting losses on any given trade and keeping emotions in check.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (
        newtradingPlan?.maximum_trades_per_day >
        tradingPlan?.maximum_trades_per_day
      ) {
        const chatMessage = `You have reached the maximum number of trades allowed for today. Limiting the number of trades helps you avoid overtrading and maintain discipline. Every trade should be carefully considered rather than driven by impulse. Stick to the plan and avoid unnecessary risks.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (
        newtradingPlan?.maximum_position_size >
        tradingPlan?.maximum_position_size
      ) {
        const chatMessage = `You are about to exceed your maximum position size. Remember, keeping position sizes within limits prevents overexposure to risk. Managing position size is key to maintaining a balanced and sustainable trading approach.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (newtradingPlan?.avoid_time_of_day === true) {


        const chatMessage = `You are approaching a time you've decided to avoid trading. Sticking to this rule can help you avoid volatile or less predictable market conditions that don't align with your strategy. Sometimes, the best trade is the one you don’t take.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      if (newtradingPlan?.avoid_day_of_week === true) {
        const chatMessage = `It's ${tradingPlan.avoid_day_of_week}, which is set as a day to avoid trading. Remember, avoiding trading on certain days can help you stay clear of historically less favorable market conditions or times when your strategy is less effective. Use this time to analyze past performance or simply take a break.`;

        dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'alert',
            key: ''
          })
        ).unwrap();
      }
      // Update the refs to the current values
      prevNewTradingPlan.current = newtradingPlan;
      prevTradingPlan.current = tradingPlan;
    }
  }, [newtradingPlan]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [chatMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(); // Auto-scroll to the bottom when chatMessages change
  }, [uniqueMessages, isLoading]);

  const handleSendMessage = async () => {
    if (chatMessage.trim() !== '') {
      dispatch(
        addUserMessage({
          text: chatMessage,
          type: 'user',
          timestamp: new Date().toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        })
      );
      setChatMessage(''); // Clear input field after sending message
      setIsLoading(true);
      try {
        await dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: selectedLogin,
            message: chatMessage,
            name: userName,
            type: 'bot',
            key: ''
          })
        ).unwrap();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        message.error('Failed to send message', 1);
      }
    }
  };

  return (
    <div>
      <div className="chat-header">
        <p>The Trading Plan</p>
      </div>
      <div>
        <div className="chat-block">
          <div className="chat-preview-container">
            <div className="chat-preview">
              {tradingPlan && (
                <div className="tranding-plan">
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.daily_profit_target >
                        tradingPlan?.daily_profit_target
                          ? 'yellow-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="yellow-progress">
                      <p>
                        Reach{' '}
                        {tradingPlan?.daily_profit_target !== undefined
                          ? `${
                              tradingPlan?.daily_profit_target < 0 ? '-$' : '$'
                            }${Math.abs(tradingPlan?.daily_profit_target)}`
                          : 'N/A'}{' '}
                        profit target
                      </p>

                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="progress-border">
                          <Progress
                            percent={
                              (newtradingPlan?.daily_profit_target /
                                tradingPlan?.daily_profit_target) *
                              100
                            }
                            strokeLinecap="square"
                            strokeColor={'#00c8b5'}
                            trailColor={'#80e4da'}
                            showInfo={false}
                          />
                        </div>
                        {tradingPlan?.daily_profit_target && (
                          <label htmlFor="file">
                            <b>
                              {newtradingPlan?.daily_profit_target !== undefined
                                ? `${
                                    newtradingPlan?.daily_profit_target < 0
                                      ? '-$'
                                      : '$'
                                  }${Math.abs(
                                    newtradingPlan?.daily_profit_target
                                  )}`
                                : 'N/A'}
                            </b>{' '}
                            /{' '}
                            {tradingPlan?.daily_profit_target !== undefined
                              ? `${
                                  tradingPlan?.daily_profit_target < 0
                                    ? '-$'
                                    : '$'
                                }${Math.abs(tradingPlan?.daily_profit_target)}`
                              : 'N/A'}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.profit_target_per_trade >
                        tradingPlan?.profit_target_per_trade
                          ? 'yellow-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="yellow-progress">
                      <p>
                        Aim for ${tradingPlan?.profit_target_per_trade} Profit
                        per Trade
                      </p>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="progress-border">
                          <Progress
                            percent={
                              (newtradingPlan?.profit_target_per_trade /
                                tradingPlan?.profit_target_per_trade) *
                              100
                            }
                            strokeLinecap="square"
                            strokeColor={'#00c8b5'}
                            trailColor={'#80e4da'}
                            showInfo={false}
                          />
                        </div>
                        {tradingPlan?.profit_target_per_trade && (
                          <label htmlFor="file">
                            <b>
                              {newtradingPlan?.profit_target_per_trade !==
                              undefined
                                ? `${
                                    newtradingPlan?.profit_target_per_trade < 0
                                      ? '-$'
                                      : '$'
                                  }${Math.abs(
                                    newtradingPlan?.profit_target_per_trade
                                  )}`
                                : 'N/A'}
                            </b>{' '}
                            /{' '}
                            {tradingPlan?.profit_target_per_trade !== undefined
                              ? `${
                                  tradingPlan?.profit_target_per_trade < 0
                                    ? '-$'
                                    : '$'
                                }${Math.abs(
                                  tradingPlan?.profit_target_per_trade
                                )}`
                              : 'N/A'}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.maximum_daily_loss >
                        tradingPlan?.maximum_daily_loss
                          ? 'red-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="yellow-progress">
                      <p>
                        Do not exceed{' '}
                        {tradingPlan?.maximum_daily_loss !== undefined
                          ? `${
                              tradingPlan?.maximum_daily_loss < 0 ? '-$' : '$'
                            }${Math.abs(tradingPlan?.maximum_daily_loss)}`
                          : 'N/A'}{' '}
                        daily max loss
                      </p>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="progress-border">
                          <Progress
                            percent={
                              (newtradingPlan?.maximum_daily_loss /
                                tradingPlan?.maximum_daily_loss) *
                              100
                            }
                            strokeLinecap="square"
                            strokeColor={
                              newtradingPlan?.maximum_daily_loss >
                              tradingPlan?.maximum_daily_loss
                                ? '#ff2e48'
                                : '#00c8b5'
                            }
                            trailColor={'#80e4da'}
                            showInfo={false}
                          />
                        </div>
                        {tradingPlan?.maximum_daily_loss && (
                          <label for="file">
                            <b>
                              {newtradingPlan?.maximum_daily_loss !== undefined
                                ? `${
                                    newtradingPlan?.maximum_daily_loss < 0
                                      ? '-$'
                                      : '$'
                                  }${Math.abs(
                                    newtradingPlan?.maximum_daily_loss
                                  )}`
                                : 'N/A'}
                            </b>{' '}
                            /{' '}
                            {tradingPlan?.maximum_daily_loss !== undefined
                              ? `${
                                  tradingPlan?.maximum_daily_loss < 0
                                    ? '-$'
                                    : '$'
                                }${Math.abs(tradingPlan?.maximum_daily_loss)}`
                              : 'N/A'}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div className="green-dote"></div>
                    <div className="yellow-progress">
                      <div style={{ marginTop: '3px' }}></div>
                      <p>
                        Stop loss of {tradingPlan?.stop_loss < 0 ? '-' : ''}$
                        {Math.abs(tradingPlan?.stop_loss)} per trade
                      </p>
                      <div style={{ marginTop: '3px' }}></div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.maximum_trades_per_day >
                        tradingPlan?.maximum_trades_per_day
                          ? 'red-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="yellow-progress">
                      <p>
                        Do not exceed {tradingPlan?.maximum_trades_per_day}{' '}
                        Trade per day
                      </p>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="progress-border">
                          <Progress
                            percent={
                              (newtradingPlan?.maximum_trades_per_day /
                                tradingPlan?.maximum_trades_per_day) *
                              100
                            }
                            strokeLinecap="square"
                            strokeColor={
                              newtradingPlan?.maximum_trades_per_day >
                              tradingPlan?.maximum_trades_per_day
                                ? '#ff2e48'
                                : '#00c8b5'
                            }
                            trailColor={'#80e4da'}
                            showInfo={false}
                          />
                        </div>
                        {tradingPlan?.maximum_trades_per_day && (
                          <label for="file">
                            <b>{newtradingPlan?.maximum_trades_per_day} </b> /{' '}
                            {tradingPlan?.maximum_trades_per_day}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.maximum_position_size >
                        tradingPlan?.maximum_position_size
                          ? 'red-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="red-progress">
                      <p>
                        Do not exceed {tradingPlan?.maximum_position_size} lots
                        per day
                      </p>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="progress-border">
                          <Progress
                            percent={
                              (newtradingPlan?.maximum_position_size /
                                tradingPlan?.maximum_position_size) *
                              100
                            }
                            strokeLinecap="square"
                            strokeColor={
                              newtradingPlan?.maximum_position_size >
                              tradingPlan?.maximum_position_size
                                ? '#ff2e48'
                                : '#00c8b5'
                            }
                            trailColor={'#80e4da'}
                            showInfo={false}
                          />
                        </div>
                        {tradingPlan?.maximum_position_size && (
                          <label for="file">
                            <b>{newtradingPlan.maximum_position_size} </b> /{' '}
                            {tradingPlan.maximum_position_size}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.avoid_time_of_day === true
                          ? 'red-dote'
                          : 'green-dote'
                      }
                    ></div>
                    <div className="red-progress">
                      <div style={{ marginTop: '3px' }}></div>
                      <p>
                        Exercise caution around {tradingPlan?.avoid_time_of_day}
                      </p>
                      <div style={{ marginTop: '3px' }}></div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-1">
                    <div
                      className={
                        newtradingPlan?.avoid_day_of_week === true
                          ? 'red-dote'
                          : 'green-dote'
                      }
                      style={{ marginTop: 3 }}
                    ></div>
                    <div className="red-progress" style={{ marginTop: 2 }}>
                      <p>
                        Exercise caution on {tradingPlan?.avoid_day_of_week}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="message-container-scroll">
                <div className="message-container">
                  {uniqueMessages.map((msg, index) => {
                    if (msg.type === 'bot') {
                      return (
                        <div
                          key={`${index}`}
                          className="multiple-message-container"
                        >
                          <div className="outer-message-container">
                            <div className="chat-icon-skeleton">
                              <img alt="FractaLogo" src={FractaLogo} />
                            </div>
                            <div
                              className="bot-message-container"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              <div className="bot-message">{msg.text}</div>
                              <div
                                className="time-div icon-adjust"
                                style={{ display: 'flex' }}
                              >
                                {msg.timestamp}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (msg.type === 'user') {
                      return (
                        <div
                          key={`${index}`}
                          className="outer-user-message-container"
                        >
                          <div className="chat-icon-skeleton last-item">
                            <div className="chat-icon-skeleton-text">
                              {firstLetterOfUsername}
                            </div>
                          </div>
                          <div className="user-message-container">
                            <div className="user-message">{msg.text}</div>
                            <div
                              className="time-div icon-adjust"
                              style={{ display: 'flex' }}
                            >
                              {msg.timestamp}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (msg.type === 'alert') {
                      return (
                        <div
                          key={`${index}`}
                          className="outer-user-message-container"
                        >
                          <div className="outer-message-container">
                            <div className="chat-icon-skeleton">
                              <img alt="FractaLogo" src={FractaLogo} />
                            </div>
                            <div
                              className="alert-message-container"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              <div className="bot-message">
                                <strong>Alert:</strong> {msg.text}
                              </div>
                              {/* <div
                                className="time-div icon-adjust"
                                style={{ display: 'flex' }}
                              >
                                {msg.timestamp}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="default-chatting">
                  {isLoading && (
                    <>
                      <div className="chat-icon-skeleton">
                            <img alt="FractaLogo" src={FractaLogo} />
                      </div>
                      <div class="dotsContainer">
                        <span id="dot1"></span>
                        <span id="dot2"></span>
                        <span id="dot3"></span>
                      </div>
                    </>
                  )}
                </div>
                <div ref={messagesEndRef} style={{ marginBottom: 20 }} />{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="chat-footer">
          <textarea
            placeholder="Send a message"
            className="chat-input"
            maxLength="225"
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            style={{
              width: '100%',
              resize: 'none',
              overflowY: chatMessage.split('\n').length > 3 ? 'auto' : 'hidden', // Add scroll if more than 3 lines
              maxHeight: '4.5rem' // Maximum height for 3 lines
            }}
            ref={textareaRef}
            rows="1"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(); // Send the message
              } else if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault(); // Prevent the default behavior
                const cursorPosition = textareaRef.current.selectionStart; // Get the current cursor position
                const textBeforeCursor = chatMessage.substring(
                  0,
                  cursorPosition
                ); // Text before cursor
                const textAfterCursor = chatMessage.substring(cursorPosition); // Text after cursor
                // Insert a single newline at the cursor position
                setChatMessage(textBeforeCursor + '\n' + textAfterCursor);
                // Adjust the cursor position after inserting the newline
                setTimeout(() => {
                  textareaRef.current.selectionStart = cursorPosition + 1;
                  textareaRef.current.selectionEnd = cursorPosition + 1;
                }, 0);
              }
            }}
          />
          <img
            className="chat-disabled-send-button"
            title="Send message"
            alt="send button"
            src={
              chatMessage && chatMessage.trim().length
                ? require('../../assets/images/send-icon.png')
                : require('../../assets/images/send_disable_icon.png')
            }
            style={{ display: 'block', width: 20, height: 20 }}
            onClick={handleSendMessage}
          />
        </div>
      </div>
    </div>
  );
}
